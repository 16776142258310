import {React, lazy, Suspense} from 'react'

import './Loader.css'

function Loader() {
  return (
    <>
        <div className="loader_main">
          <span className="loader"></span>
        </div>
    </>
  )
}

export default Loader